import React from 'react';
import { Typography } from '@mui/material';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import FaqsMotion from './FaqsMotion';
import Book from '../components/book';

// Images for book 1
import kitab1 from '../images/kitab1.png';
import kitab11 from '../images/kitab1.1.png';
import kitab12 from '../images/kitab1.2.png';
import kitab13 from '../images/kitab1.3.png';
import kitab14 from '../images/kitab1.4.png';
import kitab15 from '../images/kitab1.5.png';
import kitab16 from '../images/kitab1.6.png';

// Images for book 2
import kitab2 from '../images/kitab2.jpeg';
import kitab21 from '../images/kitab2.1.png';
import kitab22 from '../images/kitab2.2.png';
import kitab23 from '../images/kitab2.3.png';
import kitab24 from '../images/kitab2.4.png';
import kitab25 from '../images/kitab2.5.png';
import kitab26 from '../images/kitab2.6.png';
import kitab27 from '../images/kitab2.7.png';
import kitab28 from '../images/kitab2.8.png';
import kitab29 from '../images/kitab2.9.png';

import { useTranslation } from 'react-i18next';


const Books = () => {

  const {t}=useTranslation();

  const moveValues=
  {
    hidden: { opacity: 0, x: 150 },
    visible: { opacity: 1, x: 0 },
  }
const moveValuesLeft=
  {
    hidden: { opacity: 0, x: -150 },
    visible: { opacity: 1, x: 0 },
  }

  return (
    <div>
      <Navbar />

      <div className="books-container">
        <FaqsMotion
          sx={{ width: '33%', position: 'absolute' }}
          duration={{ duration: 0.5 }}
          move={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <Typography variant="h3" className="books-title" >
          {t("booksTitle")}
          </Typography>
        </FaqsMotion>

        <div className="books">
        <FaqsMotion duration={{duration:1.6}} move={moveValuesLeft} >
          <Book 
            title={t("book1Title")}
            // 
            images={[kitab1, kitab11, kitab12, kitab13, kitab14, kitab15, kitab16]} 
          />
</FaqsMotion>
<FaqsMotion duration={{duration:1.6}} move={moveValues} >
          <Book 
          title={t("book2Title")}
            //
            images={[kitab2, kitab21, kitab22, kitab23, kitab24, kitab25, kitab26, kitab27, kitab28, kitab29]} 
          />
          </FaqsMotion>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Books;
