import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const CustomArrow = ({ className, style, onClick, icon }) => (
  <div
    className={className}
    style={{ ...style, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#333', fontSize: '30px' }}
    onClick={onClick}
  >
    {icon}
  </div>
);

const Book = ({ title, images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <CustomArrow icon={<ArrowForwardIos />} />,
    prevArrow: <CustomArrow icon={<ArrowBackIos />} />,
  };

  return (
    <Card className="book-card" sx={{ maxWidth: 300, textAlign: 'center' }}>
      <Slider {...settings} className="book-slider">
        {images.map((src, index) => (
          <div key={index} className="carousel-image-container">
            <img
              src={src}
              alt={`${title} - Slide ${index + 1}`}
              className="carousel-image"
            />
          </div>
        ))}
      </Slider>
      <CardContent className='card-content glass'>
        <Typography variant="h6" component="div" className="book-title">
          {title}
        </Typography>
        
      </CardContent>
    </Card>
  );
};

export default Book;
